@use '../../sass/mixins/axis.scss';
@use '../../sass/mixins/font-style.scss';
@use '../../sass/variables/spacing.scss';

.html-preview {
  @include axis.all(spacing.$default, padding);
  overflow: auto;

  &__title {
    @include font-style.ui($size: big, $bolder: true);
    margin-bottom: spacing.$big;
  }
}