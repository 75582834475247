@use '../../sass/variables/spacing.scss';
@use '../../sass/variables/palette.scss';
@use '../../sass/mixins/font-style.scss';

.content-section {
  &:not(:first-child) {
    margin-top: spacing.$default;
  }
  
  &__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid palette.$app-border-strong;
    padding: spacing.$small;
    margin-bottom: spacing.$tiny;
  }
  
  &__icon-container {
    padding-right: spacing.$icon-text;
  }

  &__title {
    @include font-style.heading($level: 2, $margin: false);
  }
}