@use '../../../../../../../sass/mixins/axis.scss';
@use '../../../../../../../sass/mixins/font-style.scss';
@use '../../../../../../../sass/variables/spacing.scss';
@use '../../../../../../../sass/variables/palette.scss';

.document-tree {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  height: 100%;

  &__toolbar {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: spacing.$default;
    @include axis.all(spacing.$tiny, padding);
    border-bottom: 1px solid palette.$app-border;
  }

  &__document-name {
    @include font-style.ui($size: small, $bolder: true);
  }
}
