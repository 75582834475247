.sfui-user {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 5px;

  .sfui-ellipsis-div {
    overflow: hidden;
  }

  .sfui-user-name-ellipsis {
    height: unset;
  }

  .sfui-user-name {
    margin-bottom: 0;
  }
}