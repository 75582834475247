.cstcomments-tab {

  .cstcomments-container {
    height: auto;
    margin: 10px;
    overflow-y: auto;
    scrollbar-width: thin;
  }

  .cstcomments-separator {
    border-top: 1px solid #DDD;
    width: 100%;
  }

  .cstcomments-form-wrapper {
    padding: 10px;

    .textarea__input {
      min-height: unset;
      height: 100px;
    }

    .form-buttons {
      display: flex;
      flex-direction: row;
      justify-content: end;
      margin-right: 10px;

      .button {
        margin: 10px 0 10px 10px;
      }

    }

  }
}

.single-comment {
  .user-wrapper {
    height: fit-content;
    margin: 0 5px;
  }

  .comment-wrapper {
    width: calc(100% - 30px);

    .single-comment-info {
      font-size: x-small;
      margin: unset;
      height: fit-content;
      padding: unset;
    }

    .single-comment-comment {
      max-width: 420px;
    }

    .single-comment-data{
      display: flex;
      flex-direction: column;
    }
  }

}

.single-comment-separator {
  width: 100%;
  border-top: 1px solid #DDD;
  margin: 10px 0;
}
